body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  /* overflow: hidden; */
  /* height: 6080px;
  overscroll-behavior: none;
  scroll-behavior: auto; */
}
ul {
  list-style-type: none;
}
html,
body,
p,
ul,
li,
h3,
h4,
h5,
h2,
h6,
a,
img {
  margin: 0;
  padding: 0;
}
h3,
h4,
h5,
h2 {
  font-weight: 400;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
