.app-talent {
  .streamer {
    padding: 0.96rem 0.4rem;
    text-align: center;
    h3 {
      font-size: 0.68rem;
      font-weight: 600;
      color: #fff;
      line-height: 0.8rem;
      margin-bottom: 0.24rem;
      span {
        color: #fe2c55;
      }
    }
    p {
      font-size: 0.24rem;
      font-weight: 400;
      color: #ccc;
      line-height: 0.28rem;
    }
    .btn {
      display: inline-block;
      height: 0.78rem;
      background: #f40744;
      border-radius: 3rem;
      line-height: 0.78rem;
      padding: 0 0.4rem;
      color: #fff;
      font-weight: bold;
      font-size: 0.22rem;
      font-weight: 600;
      margin-top: 0.7rem;
    }
  }
  .talent-imgs {
    position: relative;
    width: 100%;
    overflow: visible;
    margin-top: 0.64rem;
    min-height: 6.88rem;
    height: auto;
    img {
      width: 2.77rem;
      position: absolute;
    }
    .img1 {
      top: 0;
      z-index: 3;
      left: -0.44rem;
      transform: rotate(5deg);
    }
    .img2 {
      top: 0.7rem;
      left: 25.6%;
      transform: rotate(-20deg);
      z-index: 2;
    }
    .img3 {
      top: 0.26rem;
      right: -0.44rem;
      transform: rotate(10deg);
    }
  }
  // 公司介绍
  .company-info {
    width: 100%;
    background-color: #ff1652;
    .border {
      padding: 0.4rem;
      position: relative;
    }
    .border::after {
      position: absolute;
      content: "";
      height: 0.02rem;
      background-color: rgba(255, 255, 255, 0.8);
      width: 3.6rem;
      left: 0;
      bottom: 0;
    }
    .border3::after {
      display: none;
    }
    .ant-space {
      display: flex;
      margin-top: 0.08rem;
    }
    h4 {
      font-size: 0.32rem;
      font-weight: 400;
      color: #fff;
      line-height: 0.38rem;
    }
    h3 {
      font-size: 0.72rem;
      font-weight: 800;
      color: #fff;
      line-height: 0.84rem;
      display: block;
    }
  }
  // 历程
  .course {
    position: relative;
    margin: 1.28rem 0 1.56rem;
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
    p {
      font-size: 0.24rem;
      font-weight: 400;
      color: #999;
      line-height: 0.32rem;
      position: absolute;
      left: 10.6%;
      top: 0.36rem;
    }
    .p2 {
      left: 29.6%;
      top: 0.28rem;
    }
    .active {
      left: 48%;
      top: 0.32rem;
      font-size: 0.32rem;
      font-weight: 600;
      color: #ff1652;
    }
    .describe {
      margin: 1rem 0.68rem 0;
      text-align: center;
      font-size: 0.32rem;
      font-weight: 500;
      color: #fff;
      line-height: 0.38rem;
    }
  }
  // 更多创作者
  .more-create {
    width: 100%;
    margin-bottom: 1.28rem;
    h3 {
      font-size: 0.6rem;
      font-weight: bold;
      color: #fff;
      padding: 0 0.4rem;
      margin-bottom: 0.28rem;
    }
    .blue {
      color: #23f2ec;
    }
    .red {
      color: #ff1652;
    }
    .images {
      margin: 0.5rem 0 1.28rem;
      position: relative;
      img {
        margin-right: 1.6%;
        vertical-align: top;
      }
      img:last-child {
        margin-right: 0;
        position: absolute;
        right: 0;
        top: 26.5%;
      }
    }
    .top-talent {
      width: 100%;
      overflow: hidden;
      clear: both;
      margin-top: 0.48rem;
      .avatar {
        float: left;
        width: 2.8rem;
        height: 0.76rem;
        background: #181818;
        border-radius: 0.16rem;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: start;
        margin-left: 0.32rem;
        margin-bottom: 0.32rem;
        > div {
          padding: 0.1rem 0.16rem 0;
        }
        img {
          display: block;
          width: 0.76rem;
          height: 0.76rem;
          object-fit: cover;
          border-radius: 0.16rem;
          background-color: #313131;
        }
        p {
          font-size: 0.2rem;
          font-weight: 600;
          color: #fff;
          line-height: 0.23rem;
          max-width: 1.76rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .aleft {
    text-align: left;
  }
  .aright {
    text-align: right;
  }
  .acenter {
    text-align: center;
  }
}
