.app-settle {
  background: #000;
  width: 7.5rem;
  margin: 0 auto;
  font-family: Montserrat, Montserrat;
  color: #fff;
  .app-header-bar {
    position: fixed;
    padding-top: 0.78rem;
    width: 7.5rem;
    margin: 0 auto;
    background: #000;
    z-index: 100;
    div {
      height: 0.62rem;
      padding: 0 0.26rem;
      display: flex;
      align-items: center;
      img {
        width: 0.48rem;
      }
    }
  }
  .banner {
    padding-top: 0.4rem;
    width: 100%;
    background: url("../images/settlebg.png") no-repeat;
    background-size: cover;
    .img1 {
      width: 2.92rem;
      margin: 0 auto;
      display: block;
    }
    .max-title {
      font-family: Arial Black, Montserrat;
      // -webkit-animation: ;
      // font-family: Futura-Bold;
      -webkit-text-stroke: 0.08rem #fff; /* Safari */
      text-stroke: 0.08rem #fff;
      font-size: 0.96rem;
      line-height: 1.12rem;
      margin: 0.42rem 0 0.1rem;
      font-weight: bold;
      text-align: center;
      color: #fff;
    }
    .title1 {
      font-family: Montserrat;
      font-size: 0.4rem;
      line-height: 0.47rem;
      font-weight: 500;
      text-align: center;
      color: #fff;
      width: 6.86rem;
      margin: 0 auto;
    }
    .title2 {
      font-family: Montserrat;
      font-weight: bold;
      color: #20f4ef;
      text-align: center;
      font-size: 0.52rem;
      line-height: 0.61rem;
      margin: 0.15rem auto 0;
      width: 6.86rem;
    }
  }
  .title3 {
    font-family: Montserrat;
    // font-family: Archivo-Black;
    font-weight: bold;
    color: #fe2858;
    width: 6.86rem;
    text-align: center;
    font-size: 0.52rem;
    line-height: 0.61rem;
    margin: 0.07rem auto 0.36rem;
  }
  .introduce1,
  .introduce2 {
    background: url("../images/settbg1.png") no-repeat;
    background-size: cover;
    width: 6.54rem;
    margin: 0 auto;
    padding: 0.4rem 0.3rem;
    position: relative;
    border-radius: 0.3rem;
    border: 0.01rem solid #333;
    h3 {
      // border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
      font-size: 0.4rem;
      font-weight: bold;
      color: #fff;
      line-height: 0.47rem;
      padding-bottom: 0.32rem;
      text-align: left;
      width: 5.02rem;
      font-family: Montserrat;
      // font-family: Arial Black;
      position: relative;
      z-index: 3;
    }
    .horn {
      position: absolute;
      right: -0.16rem;
      top: -0.46rem;
      width: 1.28rem;
      z-index: 2;
    }
    .horn2 {
      position: absolute;
      right: -0.3rem;
      top: -0.16rem;
      width: 2.2rem;
      z-index: 2;
    }
    .text1 {
      font-weight: 400;
      font-size: 0.32rem;
      color: #fff;
      line-height: 0.38rem;
    }
  }
  .introduce2 {
    width: 6.86rem;

    .horn {
      top: 0;
      right: 0.16rem;
    }
    .text {
      margin-top: 0.3rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.36rem;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .img1 {
        width: 0.36rem;
        display: block;
        margin-right: 0.08rem;
        // margin-right: 0.08rem;
      }
    }
  }
  // 业务范围
  .range-bg {
    padding: 0 0 0 0.48rem;
    margin: 0.46rem 0 0.14rem;
    ul {
      clear: both;
      overflow: hidden;
    }
    li {
      width: auto;
      text-align: center;
      margin: 0 0.45rem;
      float: left;
      margin-bottom: 0.32rem;
      img {
        width: 0.72rem;
        display: block;
        margin: 0 auto;
      }
      span {
        margin-top: 0.08rem;
        display: block;
        font-size: 0.28rem;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        color: #fff;
        text-align: center;
      }
    }
  }
  .information {
    width: 7.5rem;
    margin: 0 auto;
    height: 1.08rem;

    div {
      float: left;
      box-sizing: border-box;
      text-align: center;
      position: relative;
    }
    .border {
      position: absolute;
      height: 0.62rem;
      text-align: center;
      padding: 0;
      width: 0.02rem;
      background: #fff;
      top: 0.24rem;
      right: 0;
    }
    p {
      font-size: 0.48rem;
      font-family: Arial Black, Montserrat;
      font-weight: bold;
      color: #fff;
      line-height: 0.56rem;
      margin-bottom: 0.16rem;
    }
    h3 {
      font-size: 0.28rem;
      font-family: Montserrat;
      font-weight: 400;
      color: #cecece;
      line-height: 0.33rem;
      margin: 0;
    }
  }
  // 其他左右有小图的标题
  .settle-title {
    text-align: center;
    font-size: 0.36rem;
    font-weight: bold;
    color: #fff;
    line-height: 0.5rem;
    margin: 0.64rem auto 0.48rem;
    width: 6.86rem;
    font-family: Montserrat;
    // font-family: Arial Black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      display: block;
      margin: 0 0.16rem;
      width: 0.51rem;
      margin-top: -0.1rem;
    }
    p {
      flex: 1;
    }
    span {
      color: #fe2c55;
      // font-family: Montserrat;
      font-weight: bold;
      // -webkit-text-stroke: 0.02rem #fe2c55; /* Safari */
      // text-stroke: 0.02rem #fe2c55;
    }
  }
  .arrow-down {
    display: block;
    margin: 0.32rem auto;
    width: 0.41rem;
  }
  .advantage,
  .advantage1,
  .advantage2 {
    border-radius: 0.4rem;
    width: 6.86rem;
    margin: 0.48rem auto 0;
    background-color: #25f4ee;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.36rem 0.05rem 0.6rem 0.24rem;
    box-sizing: border-box;
    h3 {
      font-family: Montserrat;
      font-size: 0.32rem;
      line-height: 0.38rem;
      font-weight: bold;
      margin-bottom: 0.08rem;
    }
    .text {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 0.2rem;
      line-height: 0.23rem;
    }
    .number {
      font-family: Arial Black, Montserrat;
      font-weight: bold;
      font-size: 0.56rem;
      line-height: 0.66rem;
      text-align: center;
      width: 1.24rem;
      margin-right: 0.22rem;
      -webkit-text-stroke: 0.02rem #000; /* Safari */
      text-stroke: 0.02rem #000;
    }
    img {
      width: 1.32rem;
    }
    div {
      flex: 1;
      width: 3.93rem;
    }
  }
  .advantage {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .advantage1 {
    background-color: #fe2c55;
    padding-right: 0.1rem;
    color: #fff;
    margin-top: -0.36rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .number {
      -webkit-text-stroke: 0.02rem #fff; /* Safari */
      text-stroke: 0.02rem #fff;
    }
    h3 {
      color: #fff;
    }
    img {
      width: 1.16rem;
    }
  }
  .advantage2 {
    background-color: #fff;
    padding-right: 0.2rem;
    padding-bottom: 0.36rem;
    margin-top: -0.36rem;
    img {
      width: 0.92rem;
    }
  }
  .influencer {
    border-radius: 0.32rem;
    width: 6.86rem;
    margin: 0 auto 0.64rem;
    border: 0.02rem solid rgba(28, 91, 90, 1);
    overflow: hidden;
    background: #081213;
    // border-image: linear-gradient(
    //     180deg,
    //     rgba(28, 91, 90, 1),
    //     rgba(6, 19, 19, 0)
    //   )
    //   2 2;
    box-sizing: border-box;
    padding: 0.4rem 0.32rem;
    h3 {
      padding-bottom: 0.4rem;
      border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
      font-family: Montserrat;
      font-weight: bold;
      font-size: 0.4rem;
      color: #fff;
      text-align: center;
    }
    .talent-warp {
      height: 4.16rem;
      margin-top: 0.54rem;
      overflow-x: scroll;
      margin-right: -0.32rem;
      margin-left: -0.32rem;
      &::-webkit-scrollbar {
        display: none;
      }
      .warp {
        width: 7.76rem;
        margin-left: 0.2rem;
      }
      .list {
        width: 1.86rem;
        float: left;
        margin-right: 0.04rem;
        img {
          width: 100%;
          display: block;
        }
        p {
          margin-top: 0.08rem;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 0.24rem;
          color: #ffffff;
          line-height: 0.28rem;
          text-align: center;
        }
      }
    }
    .desc {
      font-family: Montserrat;
      font-weight: 400;
      font-size: 0.32rem;
      color: #fff;
      line-height: 0.38rem;
      margin-top: 0.22rem;
    }
    .img1 {
      width: 3.3rem;
      display: block;
      margin: 0 auto;
    }
    .brand-title {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 0.28rem;
      color: #f4f4f4;
      line-height: 0.33rem;
      margin-bottom: 0.32rem;
      text-align: center;
    }
    .product-list {
      margin: 0 -0.18rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .list {
        width: 33%;
        img {
          width: 100%;
          display: block;
        }
        p {
          font-family: Montserrat;
          font-weight: 600;
          font-size: 0.24rem;
          color: #f4f4f4;
          line-height: 0.28rem;
          text-align: center;
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }

  .brand-warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 6.86rem;
    margin: 0 auto 0.32rem;
    img {
      width: 2.2rem;
      margin-right: 0.24rem;
    }
    .desc {
      flex: 1;
      margin-top: -0.16rem;
      h3 {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 0.4rem;
        color: #20f4ef;
        line-height: 0.47rem;
        margin-bottom: 0.08rem;
      }
      p {
        font-family: Montserrat;
        font-weight: 400;
        font-size: 0.28rem;
        color: #ffffff;
        line-height: 0.38rem;
        span {
          font-family: Montserrat;
          font-weight: bold;
          font-size: 0.28rem;
          margin-right: 0.05rem;
        }
      }
    }
  }

  .form-desc {
    width: 6.68rem;
    margin: 0 auto 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 1.73rem;
      display: block;
      margin-left: 0.05rem;
    }
    p {
      font-family: Montserrat;
      font-weight: 400;
      font-size: 0.32rem;
      color: #fff;
      line-height: 0.38rem;
    }
  }
  .settle-btn,
  .settle-btn[disabled],
  .settle-btn[disabled]:hover,
  .settle-btn[disabled]:focus,
  .settle-btn[disabled]:active {
    width: 6.86rem;
    height: 0.88rem;
    background: #25f4ee;
    border-radius: 3rem;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 0.32rem;
    color: #353535 !important;
    text-align: center;
    border-color: #25f4ee;
    box-sizing: border-box;
  }

  .settle-btn:hover,
  .settle-btn:active,
  .settle-btn:focus,
  .settle-btn:hover {
    background-color: #25f4ee !important;
    border-color: #25f4ee !important;
    color: #353535;
  }
  .ant-btn.settle-btn[disabled]::before {
    display: block;
  }
  .anchor-point {
    position: fixed;
    right: 0.2rem;
    top: 28%;
    z-index: 100;
    display: block;
    img {
      width: 0.88rem;
      margin: 0 auto 0.08rem;
      display: block;
    }
    p {
      padding: 0.08rem 0.16rem;
      border-left: 0.04rem solid #fe2858;
      border-right: 0.04rem solid #20f4ef;
      border-radius: 0.08rem;
      background: #333;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 0.24rem;
      color: #fff;
    }
  }
}
