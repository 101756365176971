.app-home {
  .banner {
    background: url("../images/banner.png") no-repeat;
    background-size: contain;
    padding: 0 0.4rem;
    margin-top: 0.5rem;
    .title {
      font-size: 0.48rem;
      font-weight: 600;
      color: #25f4ee;
      margin-bottom: 0.24rem;
      text-align: center;
      span {
        color: #fe2c55;
      }
    }
    .min-title {
      font-size: 0.32rem;
      font-weight: 500;
      color: #fff;
      line-height: 0.38rem;
      margin-bottom: 0.24rem;
      text-align: center;
    }
    .text {
      font-size: 0.2rem;
      font-weight: 400;
      color: #ccc;
      line-height: 0.23rem;
      text-align: center;
    }
    // 我是商家按钮
    .business {
      margin: 0.4rem auto 0.72rem;
      text-align: center;
      .store-btn {
        height: 0.62rem;
        line-height: 0.62rem;
        background: #fff;
        color: #f40744;
        border-radius: 1.6rem;
        text-align: center;
        font-size: 0.24rem;
        padding: 0 0.28rem;
        font-family: Montserrat, Montserrat;
        display: inline-block;
      }
      .store-active {
        background: #f40744;
        color: #fff;
        margin-right: 0.48rem;
      }
    }
    .id-business {
      .store-btn {
        padding: 0 0.2rem;
      }
      .store-active {
        margin-right: 0.1rem;
      }
    }
    // 网红数据
    .information {
      margin: 0 auto 1.28rem;
      background: url("../images/bg1.png") no-repeat;
      background-size: contain;
      padding: 0.52rem 0 0.1rem;
      text-align: center;
      p {
        font-size: 0.26rem;
        color: #fff;
        margin-bottom: 0.1rem;
      }
      h3 {
        font-size: 0.64rem;
        font-weight: 600;
        color: #fff;
        line-height: 0.75rem;
        margin-bottom: 0.4rem;
      }
    }
    // 业务范围
    .range {
      h3 {
        font-size: 0.48rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0.48rem;
        text-align: center;
        span {
          color: #25f4ee;
        }
      }
      .range-bg {
        padding: 0 0 0 0.2rem;
        margin-bottom: 1.3rem;
        ul {
          clear: both;
          overflow: hidden;
        }
        li {
          width: auto;
          text-align: center;
          margin: 0 0.45rem;
          float: left;
          margin-bottom: 0.32rem;
          img {
            width: 0.72rem;
            display: block;
            margin: 0 auto;
          }
          span {
            margin-top: 0.08rem;
            display: block;
            font-size: 0.28rem;
            font-family: Montserrat, Montserrat;
            font-weight: 400;
            color: #fff;
            text-align: center;
          }
        }
      }
      .map {
        background: url("../../assest/map.png") top center no-repeat;
        background-size: contain;
        width: 6rem;
        height: 4.13rem;
        margin: 0 auto;
        position: relative;
        img {
          position: absolute;
          z-index: 5;
          width: 0.73rem;
          height: 0.73rem;
        }
        .drop1 {
          top: 0.64rem;
          left: 0.48rem;
        }
        .drop2 {
          top: 1.08rem;
          left: 1.28rem;
        }
        .drop3 {
          top: 0.62rem;
          left: 2.58rem;
        }
        .drop4 {
          top: 2.02rem;
          left: 0.74rem;
        }
        .drop5 {
          top: 3.02rem;
          left: 1.4rem;
        }
      }
    }
  }
  .app-introduce {
    p {
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.3rem;
      margin-bottom: 0.24rem;
    }
    .desc {
      margin-bottom: 0.64rem;
      img {
        width: 2.92rem;
        margin-bottom: 0.42rem;
        display: block;
      }
    }
    .tik-warp {
      position: relative;
      width: 5.06rem;
      height: 5.35rem;
      margin: 0 auto;
      img {
        position: absolute;
        z-index: 3;
        display: block;
        margin: 0;
      }
      .img1 {
        top: 0.16rem;
        left: 1.84rem;
        width: 2.1rem;
        z-index: 4;
      }
      .img2 {
        bottom: 0;
        left: 0.24rem;
        width: 4.08rem;
      }
      .img3 {
        top: 0.68rem;
        left: 0;
        width: 2.29rem;
        z-index: 5;
      }
      .img4 {
        top: 0;
        right: 0.26rem;
        width: 1.27rem;
        z-index: 5;
      }
      .img5 {
        bottom: 0.66rem;
        right: 0;
        width: 1.53rem;
        z-index: 5;
      }
    }
    .desc1 {
      h3 {
        font-size: 0.48rem;
        font-family: Montserrat, Montserrat;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.56rem;
        margin: 1.28rem 0 0.4rem;
        text-align: center;
      }
      margin-bottom: 0.66rem;
    }
    .intr-imgs {
      position: relative;
      width: 5.6rem;
      height: 5.88rem;
      margin: 0 auto;
      img {
        position: absolute;
      }
    }
    .tm1 {
      left: 0.34rem;
      width: 1.65rem;
      top: 0;
    }
    .tm2 {
      left: 2.29rem;
      width: 1.92rem;
      top: 0.48rem;
    }
    .tm3 {
      left: 1.04rem;
      width: 3.57rem;
      top: 1.48rem;
      z-index: 2;
    }
    .tm4 {
      right: 0;
      width: 1.3rem;
      top: 0.18rem;
      z-index: 3;
    }
    .tm5 {
      left: 0;
      width: 1.72rem;
      bottom: 0;
      z-index: 3;
    }
    .tm6 {
      right: 0.344rem;
      width: 1.29rem;
      bottom: 0.36rem;
      z-index: 3;
    }
  }
}
.app-cases {
  .btn {
    width: 1.72rem;
    height: 0.74rem;
    line-height: 0.74rem;
    border-radius: 0.16rem;
    opacity: 1;
    border: 0.02rem solid #f2efef;
    font-size: 0.28rem;
    color: #fff;
    text-align: center;
    font-weight: bold;
    display: block;
    float: left;
    margin-right: 0.48rem;
    margin-bottom: 0.48rem;
  }
  .active {
    background-color: #ff1652;
    border-color: #ff1652;
  }
  .cases-content {
    width: 100%;
    border-radius: 0.32rem;
    overflow: hidden;
    background-color: #fd2b54;
    img {
      display: block;
      width: 100%;
      height: 3.96rem;
    }
    .text {
      height: 2.04rem;
      color: #fff;
      padding: 0.32rem 0 0;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      text-align: center;
      > div {
        width: 40%;
      }
      .big-width {
        width: 60%;
      }
      h3 {
        font-size: 0.76rem;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.94rem;
        span {
          font-size: 0.28rem;
          vertical-align: bottom;
          margin-left: 0.05rem;
        }
      }
    }
  }
  .clear {
    padding-left: 0.28rem;
  }
}
.app-brand {
  .brand-content {
    overflow-x: scroll;
    margin-right: -0.4rem;
  }
  .brand-content::-webkit-scrollbar {
    display: none;
  }
  .scroll-warp {
    width: 15rem;
  }
  .card {
    width: 4.76rem;
    height: 6.42rem;
    background: #131313;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 0.24rem;
    margin-right: 0.24rem;
    float: left;
    img {
      width: 100%;
      height: 3.7rem;
      object-fit: cover;
    }
    h3 {
      font-size: 0.32rem;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.38rem;
      padding: 0.32rem 0.32rem 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      margin-bottom: 0.2rem;
    }
    p {
      font-size: 0.24rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.29rem;
      padding: 0 0.32rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      height: 0.84rem;
    }
    .img2 {
      width: 0.48rem;
      height: 0.48rem;
      display: block;
      margin-top: 0.22rem;
      margin-right: 0.32rem;
      float: right;
    }
    .img-warp {
      height: 3.7rem;
      overflow: hidden;
    }
  }
}
.app-warp-content {
  padding: 0 0.4rem;
}
.app-main-title {
  font-size: 0.48rem;
  font-weight: 600;
  color: #fff;
  line-height: 0.56rem;
  margin: 1.28rem auto 0.48rem;
  text-align: center;
}
.clear {
  clear: both;
  overflow: hidden;
}
.app-download {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  background-color: #000;
  border-top: 0.01rem solid rgba(255, 255, 255, 0.24);
  padding: 0.16rem 0.32rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 0.16rem;
  // grid-gap: 0.16rem;
  // -webkit-gap: 0.16rem;
  img {
    width: 0.56rem;
    display: block;
    margin-right: 0.16rem;
  }
  p {
    font-size: 0.22rem;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    color: #fff;
    line-height: 0.28rem;
    flex: 1;
    margin-right: 0.16rem;
  }
  .btn {
    background: #f40744;
    height: 0.54rem;
    line-height: 0.54rem;
    border-radius: 0.08rem;
    padding: 0 0.16rem;
    display: inline-block;
    font-size: 0.24rem;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    color: #fff;
  }
}
