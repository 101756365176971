.app-mcn {
  background: #000000;
  width: 7.5rem;
  margin: 0 auto;
  font-family: Montserrat, Montserrat;
  .logo {
    margin-bottom: 0.5rem;
    display: block;
    width: 2.4rem;
  }
  .banner {
    padding: 0 0.34rem 0 0.48rem;
    margin-bottom: 0.94rem;
    h3 {
      font-weight: 800;
      font-size: 0.7rem;
      color: #fff;
      line-height: 0.78rem;
      font-family: Arial Black, Futura-Bold;
      // -webkit-text-stroke: 0.02rem #fff; /* Safari */
      // text-stroke: 0.02rem #fff;
      span {
        color: #fe2c55;
        // -webkit-text-stroke: 0.02rem #fe2c55; /* Safari */
        // text-stroke: 0.02rem #fe2c55;
      }
    }
    .img1 {
      width: 3.01rem;
      display: block;
    }
    .img2 {
      width: 5.52rem;
      margin-top: 0.16rem;
      display: block;
    }
  }

  // 达人轮播
  .top-talent {
    width: 100%;
    overflow: hidden;
    clear: both;
    .avatar {
      float: left;
      width: 2.8rem;
      height: 0.76rem;
      background: #181818;
      border-radius: 2rem;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: start;
      margin-left: 0.32rem;
      margin-bottom: 0.32rem;
      > div {
        padding: 0.1rem 0.16rem 0;
      }
      img {
        display: block;
        width: 0.76rem;
        height: 0.76rem;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        background-color: #313131;
      }
      p {
        font-size: 0.2rem;
        font-weight: 600;
        color: #fff;
        line-height: 0.23rem;
        max-width: 1.76rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  // 立即选择
  .mcn-title {
    text-align: center;
    font-size: 0.56rem;
    font-weight: 600;
    color: #fff;
    line-height: 0.66rem;
    margin-top: 0.96rem;
    // font-family: Montserrat, Montserrat;
    font-family: Arial Black, Futura-Bold;
    img {
      display: inline-block;
      margin: 0 0.16rem;
      width: 0.51rem;
      margin-top: -0.1rem;
    }
    span {
      color: #fe2c55;
      // font-family: Montserrat;
      font-weight: bold;
      // -webkit-text-stroke: 0.02rem #fe2c55; /* Safari */
      // text-stroke: 0.02rem #fe2c55;
    }
  }
  .id-mcn-title img {
    margin: 0 0.1rem;
  }
  .exceed {
    clear: both;
    overflow: hidden;
    margin: 0.96rem 0.48rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin: 0.14rem 0 0;
      display: block;
    }
    p {
      max-width: 5.2rem;
      margin: 0 0.16rem;
      line-height: 0.6rem;
      display: inline-block;
      font-family: Arial Black, Futura-Bold;
    }
  }
  .exceed::after {
    content: "";
  }
  .sub-title {
    font-size: 0.4rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.47rem;
    text-align: center;
    margin: 0.12rem 0 0.48rem;
  }
  .introduce-mcn {
    background: url("../images/mcnbg.png") no-repeat;
    background-size: cover;
    width: 6.54rem;
    margin: 0 auto;
    padding: 0.4rem 0.3rem;
    position: relative;
    border-radius: 0.3rem;
    border: 0.01rem solid #333;
    h3 {
      // border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
      font-size: 0.32rem;
      font-weight: 900;
      color: #fff;
      line-height: 0.38rem;
      padding-bottom: 0.32rem;
      position: relative;
      width: 4.82rem;
      span {
        color: #f22e55;
        font-weight: bold;
        vertical-align: middle;
      }
    }
    h3::after {
      height: 0.01rem;
      background: rgba(255, 255, 255, 0.2);
      width: 5.66rem;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
    }
    .horn {
      position: absolute;
      right: 0.12rem;
      top: 0.12rem;
      width: 1.28rem;
      z-index: 2;
    }
    .text {
      margin-top: 0.32rem;
      font-size: 0.32rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.38rem;
      img {
        display: inline-block;
      }
      .img1 {
        width: 0.36rem;
        display: block;
        // margin-right: 0.08rem;
      }
      .img2 {
        width: 0.4rem;
        margin-left: 0.08rem;
      }
    }
  }
  // 官方限时加入
  .introduce2 {
    background: url("../images/mcnbg2.png") no-repeat;
    background-size: cover;
  }
  .arrow-down {
    display: block;
    margin: 0.32rem auto;
    width: 0.41rem;
  }
  .desc {
    background: #262626;
    border-radius: 0.16rem;
    width: 6.54rem;
    margin: 0.48rem auto;
    padding: 0.24rem 0.32rem;
    box-sizing: border-box;
    font-size: 0.24rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.28rem;
    text-align: center;
  }
  // 填写信息
  .info-body {
    width: 6.54rem;
    margin: 0.48rem auto 0.74rem;
    // position: relative;
    h3 {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 0.18rem;
      img {
        display: block;
        width: 0.72rem;
      }
      p {
        font-size: 0.32rem;
        font-weight: bold;
        color: #25f4ee;
        line-height: 0.4rem;
        margin-left: 0.24rem;
      }
    }
    .relative {
      position: relative;
      padding-top: 0.6rem;
    }
    .clear {
      clear: both;
      overflow: hidden;
      height: 0.2rem;
    }
    .big-drop {
      width: 0.2rem;
      height: 0.2rem;
      background: #fe034f;
      border-radius: 50%;
      float: left;
      margin-left: 0.02rem;
    }
    // TODO: 还没写完
    .line {
      margin: 0.08rem;
      height: 0.04rem;
      border-radius: 0.16rem;
      background: #35efe5;
      margin-right: 0.16rem;
      width: 6rem;
      float: left;
      .red-line {
        height: 100%;
        background: #f22e55;
        width: 4.96rem;
        border-radius: 0.16rem;
      }
    }
    .min-drop {
      width: 0.08rem;
      height: 0.08rem;
      background: #35efe5;
      float: right;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 0.06rem;
    }

    .vertical-line {
      position: absolute;
      top: 0.08rem;
      left: 0.1rem;
      width: 0.04rem;
      background: #fe034f;
      border-radius: 0.16rem;
      height: 100%;
      // height: calc(100% - 0.94rem);
    }
    // 标题那块
    .title-warp {
      position: relative;
      z-index: 3;
      display: flex;
      justify-content: start;
      align-items: center;
      .drop {
        width: 0.63rem;
        height: 0.48rem;
        // position: absolute;
        // z-index: 3;
        // left: 0;
        // top: 1.34rem;
        background-color: #000;
        img {
          width: 100%;
          margin: 0.12rem 0;
          display: block;
        }
      }
    }
    .btn-warp {
      display: inline-block;
      width: auto;
      z-index: 4;
      background-color: #000;
      border: 0.02rem solid #25f4ee;
      border-radius: 0.16rem;
      padding: 0.04rem;
    }
    .title-btn {
      background: #25f4ee;
      display: inline-block;
      border-radius: 0.12rem;
      font-size: 0.28rem;
      font-family: Montserrat, Montserrat;
      font-weight: 600;
      color: #000000;
      line-height: 0.36rem;
      padding: 0.06rem 0.16rem;
      min-height: 0.48rem;
    }
    .info-content {
      padding: 0.64rem 0 0 0.64rem;
      .text {
        font-size: 0.2rem;
        font-weight: 500;
        color: #c1c1c1;
        line-height: 0.23rem;
        margin-top: 0.64rem;
        span {
          color: #25f4ee;
          font-weight: bold;
        }
      }
      .iphone1 {
        width: 2.84rem;
      }
      .iphone2 {
        height: 4.9rem;
      }
    }
  }
  .app-join-now .ant-input-number-input {
    margin-top: 0.08rem;
    // height: 0.92rem;
    // line-height: 0.8rem;
  }
  .app-join-now .btn {
    width: 100%;
    border-radius: 3rem;
    height: 0.74rem;
    line-height: 0.72rem;
    margin-top: 0.24rem;
    font-size: 0.28rem;
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    line-height: 0.33rem;
  }
  .footer-text {
    font-size: 0.16rem;
    font-weight: 400;
    color: #fff;
    line-height: 0.25rem;
    text-align: center;
    margin: 1.46rem auto 0;
    padding-bottom: 0.7rem;
    text-shadow: 0px 0.08rem 0.08rem rgba(0, 0, 0, 0.25);
  }
  .show-error {
    font-size: 0.2rem;
    font-weight: 500;
    color: #f60944;
    line-height: 0.23rem;
    margin-top: 0.32rem;
    text-align: left;
    padding: 0 0.12rem;
    img {
      width: 0.2rem;
      margin-right: 0.1rem;
      vertical-align: sub;
    }
  }
}
.num-input-mcn .app-join-now .ant-input-number-input {
  margin-top: 0.28rem !important;
}

.app-mcn-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
    overflow: hidden;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  .ant-drawer-header {
    padding: 0.3rem 0.12rem 0;
    border-bottom: none;
  }

  .ant-drawer-extra {
    display: none;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .ant-drawer-close {
    margin-right: 0;
    padding: 0;
  }
  .anticon-close {
    width: 0.68rem;
    color: #000;
    svg {
      width: 0.48rem;
    }
  }
  .ant-drawer-body {
    padding: 0 0.32rem 0.7rem;
    // margin-top: -0.2rem;
    .img {
      display: block;
      margin: 0 auto;
      width: 2.96rem;
    }
    h3 {
      font-size: 0.32rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 0.38rem;
      margin: 0.32rem 0 0.28rem;
      text-align: center;
    }
    p {
      font-size: 0.28rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #808080;
      line-height: 0.33rem;
      text-align: center;
    }
    .adm-button-primary {
      width: 100%;
      height: 0.8rem;
      margin-top: 0.88rem;
      color: #fff;
      font-weight: bold;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      line-height: 0.8rem;
      background: #fa0c43;
      border-color: #fa0c43;
      border-radius: 0.08rem;
      text-align: center;
    }
  }
}
