.app-business {
  // banner
  .streamer {
    width: 100%;
    background: url("../../assest/banner1.png") bottom right no-repeat;
    background-size: 48%;
    height: 5.51rem;
    box-sizing: border-box;
    background-position-y: 1.8rem;
    .btn {
      display: inline-block;
      height: 0.78rem;
      background: #f40744;
      border-radius: 3rem;
      line-height: 0.78rem;
      padding: 0 0.4rem;
      color: #fff;
      font-weight: bold;
      font-size: 0.24rem;
      font-weight: 600;
      margin-top: 0.7rem;
    }
  }
  .streamer2 {
    background-image: url("../../assest/banner2.png");
    background-position-y: 1.6rem;
    box-sizing: border-box;
  }
  .streamer3 {
    background-image: url("../../assest/banner3.png");
    background-size: 60%;
    background-position-y: 1.3rem;
    height: 5rem;
  }
  .streamer-content {
    padding: 0.96rem 0.4rem 0;
    h3 {
      font-size: 0.68rem;
      font-weight: bold;
      color: #fff;
      margin-bottom: 0.24rem;
      line-height: 0.8rem;
      span {
        color: #fe2c55;
      }
    }
    p {
      font-size: 0.24rem;
      font-weight: 400;
      line-height: 0.28rem;
      width: 4.38rem;
      color: #ccc;
      // TODO: 按钮有block需看下空间
      // margin-bottom: 0.7rem;
    }
  }
  .application-list {
    h3 {
      border-left: 0.12rem solid #fe2c55;
      padding-left: 0.12rem;
      margin-bottom: 1.28rem;
      margin-top: 0.92rem;
    }
    h3 span {
      display: block;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.28rem;
    }
    h3 p {
      font-size: 0.48rem;
      font-weight: 600;
      color: #fff;
    }
    .list {
      display: block;
      border: 0.01rem solid #f3f0f0;
      border-radius: 0.32rem;
      padding: 0.32rem;
      min-width: 3.02rem;
      height: 2.21rem;
      box-sizing: border-box;
      // padding-right: 0;
      .img2 {
        display: inline;
      }
      p {
        font-size: 0.28rem;
        font-weight: 600;
        color: #fff;
        line-height: 0.33rem;
        margin-bottom: 0.05rem;
      }
    }
    .more {
      margin-top: 0.4rem;
      line-height: 0.32rem;
      font-size: 0.24rem;

      font-weight: 500;
      color: #fff;
      img {
        width: 0.32rem;
        margin-left: 0.08rem;
        vertical-align: bottom;
      }
    }
    .scroll-warp {
      overflow-x: scroll;
      margin-right: -0.4rem;
      overflow-y: visible;
      padding-bottom: 0.1rem;
    }
    .scroll-warp::-webkit-scrollbar {
      display: none;
    }

    .merchant-list {
      // width: 9.68rem;
      width: auto;
      white-space: nowrap;
      .list {
        // float: left;
        display: inline-block;
        margin-right: 0.2rem;
      }
    }
    .other-merchants {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list {
        width: 3.16rem;
        height: 2.12rem;
      }
    }
    .id-merchants {
      .list {
        width: 3.5rem;
      }
      .other-merchants .list {
        width: 3.26rem;
        padding: 0.32rem 0.23rem;
        .more {
          font-size: 0.22rem;
        }
      }
    }
    .en-merchants {
      .other-merchants .list {
        width: 3.26rem;
        padding: 0.32rem 0.26rem;
      }
    }
  }
  // 步骤
  .step-warp {
    border-radius: 0.32rem;
    overflow: hidden;
    margin-top: 1.28rem;
    margin-bottom: 1.28rem;
    .step {
      width: 100%;
      height: 2.18rem;
      background: #fff;
      padding: 0.32rem 0.24rem;
      box-sizing: border-box;
      position: relative;
      color: #000;
      text-align: center;
      > div {
        height: 0.54rem;
      }
      p {
        font-size: 0.32rem;
        font-weight: 500;
        margin-top: 0.32rem;
        line-height: 0.4rem;
        position: relative;
        z-index: 3;
      }
      .btn {
        height: 0.54rem;
        background: #fe2c55;
        border-radius: 0.46rem;
        line-height: 0.54rem;
        width: auto;
        min-width: 1.38rem;
        padding: 0 0.32rem;
        font-size: 0.24rem;
        font-weight: 500;
        color: #fff;
        margin: 0 auto;
        display: inline-block;
        vertical-align: top;
      }
      img {
        display: block;
        position: absolute;
        bottom: -0.2rem;
        left: 50%;
        z-index: 2;
        width: 0.48rem;
        margin-left: -0.24rem;
        // transform: rotate(90deg);
      }
    }
    .step2 {
      background-color: #ff1652;
      color: #fff;
      .btn {
        background: #fff;
        color: #ff1652;
      }
    }
    .step3 {
      background-color: #25f4ee;
      color: #000;
      .btn {
        background: #000;
      }
    }
  }
  // 为什么选择ibooming
  .advantage {
    .drop {
      width: 1.6rem;
      height: 0.06rem;
      border-radius: 0.12rem;
      background-color: #3f3f3f;
      margin-bottom: 0.32rem;
    }
    .active {
      background-color: #25f4ee;
    }
    .desc {
      background: url("../images/advantage.png") top center no-repeat;
      background-color: #171717;
      background-size: 100%;
      width: 100%;
      height: 2.96rem;
      padding: 0.4rem;
      box-sizing: border-box;
      border-radius: 0.32rem;
      h3 {
        font-size: 0.4rem;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 0.3rem;
        margin-bottom: 0.24rem;
      }
      p {
        font-size: 0.24rem;
        font-weight: 400;
        color: #fff;
        line-height: 0.26rem;
        text-align: center;
      }
    }
    .num {
      text-align: center;
      margin-top: 0.48rem;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.4rem;
    }
    span {
      color: #25f4ee;
      font-weight: 600;
      font-size: 0.32rem;
    }
  }
  .app-en-advantage {
    .desc {
      padding: 0.2rem;
      background-size: cover;
      height: 3.2rem;
      p {
        font-size: 0.2rem;
      }
    }
  }
  // 服务类型
  .app-service {
    .btn {
      display: block;
      width: 25%;
      height: 0.74rem;
      border-radius: 0.64rem;
      border: 0.02rem solid #000;
      color: #fff;
      margin-bottom: 0.48rem;
      font-size: 0.28rem;
      text-align: center;
      line-height: 0.74rem;
    }
    .active {
      border-color: #25f4ee;
      color: #25f4ee;
    }
    .list {
      background: #fff;
      border-radius: 0.32rem;
      padding: 0.64rem 0.4rem 0;
      color: #000;
      position: relative;
      box-sizing: border-box;
      height: 8.26rem;
      img {
        display: block;
        width: 3.76rem;
        margin: 0 auto 0.64rem;
      }
      h3 {
        font-size: 0.4rem;
        font-family: Montserrat, Montserrat;
        font-weight: 600;

        line-height: 0.47rem;
      }
      h4 {
        margin: 0.32rem 0;
        font-size: 0.28rem;
        font-weight: 500;
        line-height: 0.33rem;
      }
      p {
        font-size: 0.28rem;
        font-weight: 500;
        line-height: 0.33rem;
      }
    }
    .service1 {
      z-index: 1;
      // left: 0.48rem;
      // top: 2.3rem;
      // width: 5.74rem;
    }
    .service2 {
      background-color: #fe2c55;
      margin-top: -8.1rem;
      z-index: 2;
      // left: 0.32rem;
      // top: 2.46rem;
      // width: 6.06rem;
      h3,
      h4,
      p {
        color: #fff;
      }
    }
    .service3 {
      background-color: #25f4ee;
      z-index: 3;
      // width: 6.38rem;
      // top: 2.62rem;
      // left: 0.16rem;
      margin-top: -8.09rem;
    }
    .service4 {
      width: 6.7rem;
      z-index: 4;
      // top: 2.78rem;
      margin-top: -8.1rem;
      img {
        width: auto;
        height: 3.72rem;
        margin-top: -0.2rem;
      }
    }
    .none {
      display: none;
    }
    .block {
      display: block;
    }
  }
  .en-app-service {
    .list {
      height: 8.9rem;
      padding: 0.64rem 0.35rem;
    }
    .service2 {
      margin-top: -8.76rem;
    }
    .service3,
    .service4 {
      margin-top: -8.74rem;
    }
  }
}

@keyframes animat3 {
  0% {
    transform: translate(0, 100%) scale(1, 1);
  }
  100% {
    transform: translate(0, 0) scale(1, 1);
  }
}
@-webkit-keyframes animat3 {
  0% {
    transform: translate(0, 100%) scale(1, 1);
  }
  100% {
    transform: translate(0, 0) scale(1, 1);
  }
}

@keyframes animat4 {
  0% {
    transform: translate(0, 0) scale(1, 1);
  }
  100% {
    transform: translate(0, 100%) scale(1, 1);
  }
}
@-webkit-keyframes animat4 {
  0% {
    transform: translate(0, 0) scale(1, 1);
  }
  100% {
    transform: translate(0, 100%) scale(1, 1);
  }
}

.animation {
  animation: animat 0.3s;
  -webkit-animation: animat 0.3s; /* Safari and Chrome */
  animation-fill-mode: forwards;
}
.animation2 {
  animation: animat2 0.3s;
  -webkit-animation: animat2 0.3s; /* Safari and Chrome */
  animation-fill-mode: forwards;
}

.animation3 {
  animation: animat3 0.3s;
  -webkit-animation: animat3 0.3s; /* Safari and Chrome */
  animation-fill-mode: forwards;
}

.animation4 {
  animation: animat4 0.3s;
  -webkit-animation: animat4 0.3s; /* Safari and Chrome */
  animation-fill-mode: forwards;
}
