.app-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;

  .flexB {
    position: relative;
    z-index: 11;
    padding: 0.29rem 0.4rem;
    background-color: #000;
  }
}
.app-logo-warp {
  height: 0.52rem;
  overflow: hidden;
  display: block;
  width: 2.27rem;
}
.app-logo {
  height: 0.4rem;
  display: block;
}
.height {
  height: 0.53rem;
  padding-top: 0.06rem;
  box-sizing: border-box;
}
.height2 {
  height: 0.53rem;
}
.logo1 {
  width: 2.27rem !important;
  height: 0.52rem;
  padding: 0;
}
.app-lang {
  display: block;
  float: right;
  margin-right: 0.4rem;
  padding: 0.16rem 0.28rem;
  font-size: 0.28rem;
  font-family: Montserrat, Montserrat;
  font-weight: 600;
  color: #f40744;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3rem;
  height: 0.8rem;
  line-height: 0.4rem;
  border: 0.02rem solid rgba(255, 255, 255, 0.05);
  img {
    height: 0.4rem;
    width: 0.4rem;
    margin-right: 0.08rem;
    vertical-align: sub;
  }
}
.app-lang:hover {
  color: #f40744;
}
.app-menu {
  text-align: center;
  width: 0.8rem;
  height: 0.8rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 0.02rem solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 0.4rem;
  line-height: 0.8rem;
  display: block;
  float: right;
  img {
    width: 0.4rem;
    height: 0.4rem;
    display: block;
    margin: 0.17rem;
  }
}
.app-footer {
  padding: 0 0.4rem 1rem;
  margin-top: 1.28rem;
  .sub-title {
    font-size: 0.32rem;
    color: #fff;
    margin-bottom: 0.32rem;
  }
  .app-contact {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .fnML24 {
      margin-left: 0.1rem;
    }
    > div {
      width: 49%;
      margin-bottom: 0.48rem;
    }
    .flexB {
      justify-content: flex-start;
    }
    .flexB > img {
      width: 0.96rem;
    }
    img {
      display: block;
    }
    .title {
      font-size: 0.22rem;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 0.16rem;
      line-height: 0.28rem;
      img {
        margin-top: 0.02rem;
        margin-right: 0.08rem;
        float: left;
        width: 0.24rem;
      }
    }
    .phone {
      font-size: 0.2rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      display: block;
      // white-space: nowrap;
      .app-middle {
        width: 0.22rem;
        display: inline;
        margin-left: 0.04rem;
      }
    }
    .icon {
      width: 200px;
      a,
      img {
        display: block;
        float: left;
      }
    }
    .other-title {
      font-size: 16px;
      margin-bottom: 4px;
    }
    .website {
      font-size: 14px;
      display: block;
      color: #fff;
    }
    .country-info {
      margin-bottom: 0.16rem;
      height: 0.32rem;
      img {
        width: 0.32rem;
        margin-right: 0.08rem;
        display: block;
        float: left;
        vertical-align: bottom;
      }
      span {
        font-size: 0.24rem;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.32rem;
        display: block;
      }
    }
  }
  .app-brand-img {
    width: 100%;
    display: block;
  }
  .img1 {
    display: block;
    width: 1.12rem;
  }
  .title {
    font-size: 0.26rem;
    color: #fff;
    text-align: left;
    margin-bottom: 0.12rem;
  }
  .fnML24 {
    flex: 1;
    margin-left: 0.24rem;
    a {
      display: block;
    }
  }
  .fnMB40 {
    margin-bottom: 0.4rem;
  }
  .fnMB77 {
    margin-bottom: 0.77rem;
  }
  .email {
    font-size: 0.26rem;
    color: #fff;
    margin-bottom: 0.44rem;
  }
  .img2 {
    width: 0.8rem;
  }
  .logo {
    width: 1.6rem;
    display: block;
  }
  .icons {
    position: relative;
    z-index: 2;
    margin: 0.48rem 0;
    img {
      width: 0.48rem;
      margin-right: 0.48rem;
    }
  }
  .text {
    font-size: 0.24rem;
    color: #fff;
    margin-top: 0.48rem;
  }
  .agree {
    margin-right: 0.8rem;
    font-size: 0.24rem;
    color: #fff;
  }
}

.fnMT160 {
  margin-top: 1.6rem;
}
.app-modal {
  display: none;
}
.app-modal-content {
  box-sizing: border-box;
  width: 6.7rem;
  // height: 5.8rem;
  position: absolute;
  top: 2rem;
  left: 0.4rem;
  z-index: 20;
  background: linear-gradient(174deg, rgba(116, 116, 116, 0) 0%, #000000 100%);
  border-radius: 0.16rem;
  border: 0.02rem solid rgba(255, 255, 255, 0.16);
  padding: 0.3rem 0;
  -webkit-filter: none;
  filter: none;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  li {
    display: block;
    height: 0.9rem;
    line-height: 0.9rem;
    font-size: 0.32rem;
    width: 100%;
    text-align: center;
    a {
      color: #fff;
      display: block;
    }
  }
  .active,
  .active a {
    color: #f40744;
  }
}
.app-mark {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.app-dialog-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 1000;
}
.app-dialog-modal-mark {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.app-dialog-modal-body {
  background: #212121;
  border-radius: 0.4rem;
  position: absolute;
  z-index: 1010;
  left: 0.4rem;
  right: 0.4rem;
  top: 50%;
  margin-top: -3.6rem;
  .modal-close {
    position: absolute;
    bottom: -0.8rem;
    left: 50%;
    margin-left: -0.24rem;
    img {
      display: block;
      cursor: pointer;
      width: 0.48rem;
      height: 0.48rem;
    }
  }
  h3 {
    font-size: 0.48rem;
    padding-top: 0.8rem;
    text-align: center;
    width: 3.52rem;
    margin: 0 auto 0.32rem;
  }
  .modal-desc {
    font-size: 0.28rem;
    text-align: center;
    width: 5.48rem;
    margin: 0 auto;
    clear: both;
    overflow: hidden;
  }
  .modal-content {
    margin: 0.54rem auto;
    .title {
      padding-top: 0;
      margin-bottom: 0.16rem;
      text-align: left;
      font-size: 0.28rem;
      width: 100%;
    }
    .phone {
      font-size: 0.32rem;
    }
    .modal-code {
      width: 4.8rem;
      display: flex;
      align-items: center;
      margin: 0 auto 0.72rem;
      img {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}
.app-footer-border {
  width: 100vw;
  height: 0.02rem;
  background: rgba(255, 255, 255, 0.08);
  margin: 0.48rem -0.4rem;
}
.middle {
  vertical-align: middle;
  margin-left: 0.1rem;
  width: 0.26rem;
  margin-top: -0.05rem;
}
.app-toast-warp {
  .anticon-check-circle {
    display: none;
  }
  .ant-message-notice-content {
    background: #333;
    border-radius: 0.12rem;
    color: #fff;
    font-size: 0.24rem;
    font-weight: 500;
  }
}
