.app-certified {
  padding: 0.24rem;
  font-family: Montserrat;
  max-width: 7.5rem;
  color: #fff;
  margin: 0 auto;
  .in-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.22rem;
    .title1 {
      font-weight: bold;
      font-size: 0.4rem;
      line-height: 0.47rem;
      color: #fff;
    }
    p {
      font-weight: 400;
      font-size: 0.2rem;
      line-height: 0.23rem;
      margin-bottom: 0.06rem;
      img {
        width: 0.28rem;
        vertical-align: sub;
        margin-right: 0.08rem;
      }
    }
    .logo2 {
      width: 1.08rem;
      display: block;
      margin-top: 0.05rem;
    }
  }
  // 达人信息
  .info-warp {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.56rem;
    .ant-avatar {
      width: 1.28rem;
      height: 1.28rem;
    }
    > div {
      padding-left: 0.22rem;
      flex: 1;
    }
    h2 {
      font-weight: bold;
      color: #fff;
      font-size: 0.32rem;
      line-height: 0.38rem;
      .authentication {
        padding: 0.04rem 0.12rem;
        background: rgba(255, 255, 255, 0.16);
        border-radius: 0.34rem;
        font-weight: 500;
        font-size: 0.24rem;
        margin-left: 0.12rem;
        color: #fff;
        line-height: 0.32rem;
        img {
          width: 0.32rem;
          margin-right: 0.08rem;
          vertical-align: sub;
          margin-top: 0.04rem;
        }
      }
      .no {
        color: #999;
      }
    }
    .code-text {
      font-weight: 400;
      font-size: 0.2rem;
      color: #999;
      line-height: 0.23rem;
      margin-top: 0.12rem;
      span {
        color: #fff;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.28rem;
      margin: 0.12rem 0;
    }
    p {
      font-weight: 400;
      font-size: 0.24rem;
      color: #cecece;
      line-height: 0.28rem;
    }
    .img1 {
      width: 0.24rem;
      margin-right: 0.08rem;
      vertical-align: top;
    }
  }
  // 社交账号信息
  .social-warp {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: -0.08rem;
    margin-right: -0.08rem;
    > div {
      width: 2.24rem;
      border-radius: 0.2rem;
      margin: 0 0.08rem;
      overflow: hidden;
    }
    .head {
      height: 0.64rem;
      display: flex;
      padding: 0.08rem 0.18rem;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      img {
        width: 0.3rem;
      }
      > div {
        padding-left: 0.12rem;
      }
      h3 {
        font-weight: bold;
        font-size: 0.2rem;
        color: #fff;
        line-height: 0.23rem;
      }
      p {
        font-weight: 500;
        font-size: 0.2rem;
        color: #fff;
        line-height: 0.23rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 1.56rem;
      }
    }

    .head1 {
      background: linear-gradient(94deg, #7410fd 0%, #fa0169 48%, #f98d12 100%);
    }
    .head2 {
      // box-shadow: inset 3px 0 0px 0px #38f3eb, inset -3px 0 0px 0px #f82d55;
      background-color: #000;
      border-left: 0.03rem solid #38f3eb;
      border-right: 0.03rem solid #f82d55;
      border-top-left-radius: 0.2rem;
      border-top-right-radius: 0.2rem;
    }
    .head3 {
      background-color: #fa0e06;
    }
    .social-body {
      padding: 0.14rem 0 0.1rem 0.1rem;
      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 0.09rem;
        img {
          display: block;
          width: 0.36rem;
        }
        > div {
          padding-left: 0.12rem;
        }
      }
      h3 {
        font-weight: 800;
        font-size: 0.24rem;
        color: #fff;
        line-height: 0.28rem;
        -webkit-text-stroke: 0.02rem #fff; /* Safari */
        text-stroke: 0.02rem #fff;
      }
      .red {
        color: #fe2858;
        -webkit-text-stroke: 0.02rem #fe2858; /* Safari */
        text-stroke: 0.02rem #fe2858;
      }
      .blue {
        color: #2af0ea;
        -webkit-text-stroke: 0.02rem #2af0ea; /* Safari */
        text-stroke: 0.02rem #2af0ea;
      }
      p {
        font-weight: 300;
        font-size: 0.16rem;
        line-height: 0.19rem;
      }
    }
    .bg1 {
      background: url("../images/cbg1.png") no-repeat;
      background-size: cover;
    }
    .bg2 {
      background: url("../images/cbg2.png") no-repeat;
      background-size: cover;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .bg3 {
      background: url("../images/cbg3.png") no-repeat;
      background-size: cover;
    }
    .no-other-social {
      width: 100% !important;
      .ant-divider-vertical {
        border-color: rgba(255, 255, 255, 0.2);
        height: 0.64rem;
        top: 0.23rem;
        left: -0.32rem;
      }
      .social-body {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.16rem 0 0.06rem 0.1rem;
        .flex-warp {
          width: 100%;
          display: block;
          margin-bottom: 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 0.09rem;
            img {
              display: block;
              width: 0.36rem;
            }
            > div {
              padding-left: 0.12rem;
            }
          }
        }
      }
    }
  }
  .social-desc {
    margin: 0.16rem 0 0.52rem;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 0.2rem;
    color: #666;
    line-height: 0.23rem;
  }

  // 其他报价
  .quoted-price {
    // border-radius: 0.4rem;
    background: url("../images/cbg4.png") no-repeat;
    background-size: cover;
    // overflow: hidden;
    position: relative;
    margin-bottom: 0.5rem;

    .head {
      height: 0.94rem;
      background: #2af0ea;
      font-weight: bold;
      font-size: 0.48rem;
      color: #000;
      line-height: 0.94rem;
      -webkit-text-stroke: 0.02rem #000; /* Safari */
      text-stroke: 0.02rem #000;
      text-align: center;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
    }
    .img1 {
      position: absolute;
      top: -0.5rem;
      right: -0.014rem;
      width: 1.28rem;
      z-index: 10;
    }
    .quoted-body {
      padding: 0.32rem 0.28rem 0.48rem;
      border: 0.01rem solid #3a6168;
      border-top: 0;
      border-bottom-left-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
    .list-warp {
      padding-bottom: 0.12rem;
      border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
      margin-bottom: 0.32rem;
    }
    h3 {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.32rem;
      margin-bottom: 0.2rem;
      img {
        width: 0.36rem;
        margin-right: 0.16rem;
        vertical-align: text-bottom;
      }
    }
    .star {
      color: #fe2c55;
      line-height: 0.1rem;
    }

    .notice {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.28rem;
      margin-bottom: 0.32rem;
    }
    .title {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 0.32rem;
      color: #fff;
      line-height: 0.38rem;
    }
    .desc {
      font-weight: 400;
      font-size: 0.24rem;
      color: #fff;
      line-height: 0.3rem;
    }
    .notice2 {
      font-weight: 400;
      font-size: 0.2rem;
      color: #999;
      line-height: 0.23rem;
      margin-top: 0.32rem;
    }
  }
}
