.header {
  height: 120px;
  width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 12;
  .logo {
    // width: 111px;
    height: 30px;
    cursor: pointer;
    display: block;
    // margin-bottom: 10px;s
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 48px;
    margin-left: 40px;
  }

  li {
    margin: 0 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: Montserrat, Montserrat;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    a {
      color: #fff;
      white-space: nowrap;
    }
  }

  li.active a,
  li a:hover {
    color: #f40744;
  }

  .header-btn {
    width: 152px;
    height: 48px;
    line-height: 48px;
    background: #f40744;
    border-radius: 150px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    display: block;
  }

  .triangle {
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    display: inline-block;
    margin-left: 2px;
  }
  .pc-lang {
    margin-left: 20px;
    height: 45px;
  }
}
.id-header {
  li {
    margin: 0 10px;
  }
  .header-btn {
    width: 172px;
  }
}
.ant-dropdown {
  .ant-dropdown-menu {
    margin-left: -125px !important;
    margin-top: 5px !important;
    background-color: #202020 !important;
    border-radius: 16px;
    width: 297px;
    padding: 0 0 20px;
  }
  .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 20px 20px 0;
    font-size: 20px;
    font-family: Montserrat, Montserrat;
    font-weight: 600;
    color: #fff;
  }
  .ant-dropdown-menu-title-content {
    color: #fff;
    font-weight: 600;
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-item:hover {
    background: none !important;
    .ant-dropdown-menu-title-content {
      color: #ff1652;
    }
  }
}

.pc-brand-img {
  width: 1072px;
}

// 底部
.footer {
  width: 100%;
  color: #fff;
  width: 1200px;
  margin: 104px auto 36px;
  font-family: Hellix-Regular, Hellix;
  padding-bottom: 36px;
  .flexB {
    align-items: start;
  }
  .logo {
    width: 72px;
    display: block;
    // padding-top: 10px;
  }
  .email,
  .text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    margin-top: 24px;
  }
  a {
    text-decoration: underline;
    color: #fff;
    text-align: left;
  }
  .email .pc-middle {
    vertical-align: middle;
  }
}
.fnML43 {
  margin-left: 43px;
}
.pc-contact {
  color: #fff;
  margin-bottom: 17px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-start;
  > div {
    width: 33%;
    margin-bottom: 64px;
  }
  .flexB {
    justify-content: flex-start;
  }
  // 273
  img {
    display: block;
  }
  .email {
    font-size: 14px;
    line-height: 28px;
  }
  .title {
    font-size: 16px;
    font-family: Montserrat, Montserrat;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 4px;
    img {
      margin-top: 2px;
    }
  }
  .phone {
    font-size: 20px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    display: block;
  }
  .icon {
    width: 200px;
    a,
    img {
      display: block;
      float: left;
    }
  }
  .other-title {
    font-size: 16px;
    margin-bottom: 4px;
  }
  .website {
    font-size: 14px;
    display: block;
    color: #fff;
  }
  .country-info {
    margin-bottom: 20px;
    img {
      width: 28px;
      margin-right: 7px;
      display: inline-block;
      vertical-align: bottom;
    }
    span {
      font-size: 20px;
      font-family: Montserrat, Montserrat;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
  }
}
// pc提交信息到tiktok弹窗
.pc-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 1000;
  .pc-modal-mark {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .pc-modal-body {
    width: 810px;
    height: 409px;
    background: #212121;
    border-radius: 36px 36px 36px 36px;
    position: absolute;
    z-index: 1010;
    left: 50%;
    top: 50%;
    margin-top: -204px;
    margin-left: -405px;
    padding-top: 88px;
    box-sizing: border-box;
    .modal-close {
      position: absolute;
      right: -40px;
      top: -40px;
      img {
        display: block;
        cursor: pointer;
      }
    }
    h3 {
      font-size: 36px;
      font-weight: 600;
      color: #fff;
      line-height: 42px;
      text-align: center;
      margin-bottom: 24px;
    }
    .modal-desc {
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      line-height: 23px;
      margin: 0 auto 84px;
      clear: both;
      text-align: center;
      overflow: hidden;
    }
    .modal-btn {
      height: 57px;
      background: #f40744;
      border-radius: 150px;
      color: #fff;
      padding: 0 44px;
      font-size: 24px;
      font-family: Montserrat, Montserrat;
      font-weight: bold;
      color: #ffffff;
      line-height: 55px;
      margin: 0 auto;
      display: inline-block;
    }
    .modal-btn:hover {
      background: #f40744 !important;
    }
  }
}
// app提交信息到tiktok弹窗
.app-business-modal {
  .ant-modal-content {
    background-color: #212121;
    border-radius: 0.32rem;
  }
  .ant-modal-close {
    background: url("../assest/close.png") no-repeat;
    background-size: cover;
    width: 0.32rem;
    height: 0.32rem;
    right: -0.3rem;
    top: -0.3rem;
    .ant-modal-close-x,
    span {
      display: none;
    }
  }
  h3 {
    font-size: 0.32rem;
    font-weight: 600;
    color: #fff;
    line-height: 0.38rem;
    text-align: center;
    margin-bottom: 0.24rem;
  }
  .modal-desc {
    font-size: 0.24rem;
    font-weight: 400;
    color: #fff;
    line-height: 0.28rem;
    margin: 0 auto 0.96rem;
    max-width: 4.96rem;
    clear: both;
    text-align: center;
    overflow: hidden;
  }
  .modal-btn {
    height: 0.62rem;
    background: #f40744;
    border-radius: 3rem;
    color: #fff;
    padding: 0 0.28rem;
    font-size: 0.24rem;
    font-family: Montserrat, Montserrat;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.62rem;
    margin: 0 auto;
    display: inline-block;
  }
}
.footer-border {
  width: 100%;
  height: 136px;
  margin: 0 auto;
}
.pc-middle {
  vertical-align: inherit;
  width: 18px;
  margin-left: 5px;
  display: inline !important;
  cursor: pointer;
}

.flipInLogo {
  // -webkit-animation-name: flipInLogo;
  // animation-name: flipInLogo;
  // // animation
  // -webkit-animation-duration: 2s;
  // animation-duration: 2s;
  // -webkit-animation-iteration-count: infinite;
  // animation-iteration-count: infinite;
  // -webkit-animation-direction: alternate;
  // animation-direction: alternate;
  // -webkit-animation-fill-mode: both;
  // animation-fill-mode: both;
  // animation-iteration-count: infinite;
  // margin-top: -10px;

  // animation: flipInLogo 1s linear 1 normal;
  // -moz-animation: flipInLogo 1s linear 1 normal;
  // -webkit-animation: flipInLogo 1s linear 1 normal;
  // -o-animation: flipInLogo 1s linear 1 normal;

  animation: flipInLogo 15s linear infinite;
  margin-top: 0;
}
.logo-warp {
  height: 30px;
  overflow: hidden;
  width: 130px;
  .rfm-marquee-container {
    width: 30px;
    height: 111px;
  }
}
.pc-toast-warp {
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 100;
  // width: 100vw;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // .toast-main {
  //   padding: 16px;
  //   color: #fff;
  //   font-size: 20px;
  //   font-weight: 500;
  //   background-color: #333;
  //   border-radius: 6px;
  // }
  .anticon-check-circle {
    display: none;
  }
  .ant-message-notice-content {
    background: #333;
    border-radius: 6px;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
}
@keyframes flipInLogo {
  0% {
    margin-top: -30px;
  }
  10% {
    margin-top: -60px;
  }
  20% {
    margin-top: -60px;
  }
  30% {
    margin-top: -90px;
  }
  40% {
    margin-top: -90px;
  }
  50% {
    margin-top: -120px;
  }
  60% {
    margin-top: -120px;
  }
  70% {
    margin-top: -150px;
  }
  80% {
    margin-top: -150px;
  }
  90% {
    margin-top: -180px;
  }
  100% {
    margin-top: -180px;
  }
}
// @keyframes flipInLogo {
//   0% {
//     margin-top: 30px;
//   }
//   4% {
//     margin-top: 30px;
//   }
//   10% {
//     margin-top: 0px;
//   }
//   20% {
//     margin-top: 0px;
//   }
//   40% {
//     margin-top: -30px;
//   }
//   50% {
//     margin-top: -30px;
//   }
//   60% {
//     margin-top: -60px;
//   }
//   70% {
//     margin-top: -60px;
//   }
//   80% {
//     margin-top: -90px;
//   }
//   100% {
//     margin-top: -90px;
//   }
// }
