@import "~animate.css/animate.css";
@import "~wowjs/css/libs/animate.css";
a {
  cursor: pointer;
  text-decoration: none;
}
.fnML24 {
  margin-left: 24px;
}
.block {
  display: block;
}
.flexB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexA {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pc-content {
  width: 1200px;
  margin: 0 auto;
}
.min-content {
  width: 1040px;
  margin: 0 auto;
}
.home-banner {
  position: relative;
  width: 100%;
  min-width: 1200px;
  color: #fff;
  font-family: Montserrat, Montserrat;
  text-align: left;
  margin-top: 60px;
  background: url("../../assest/banner.png") center 98px no-repeat;
  background-size: contain;
  text-align: center;
  h2 {
    font-size: 80px;
    color: #25f4ee;
    span {
      color: #fe2c55;
    }
  }
  h3 {
    font-size: 44px;
    margin: 10px 0 20px;
    color: #fff;
  }
  p {
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
    max-width: 750px;
  }
  .business {
    margin: 56px auto 83px;
  }
  .store-btn {
    height: 56px;
    line-height: 56px;
    background: #fff;
    color: #f40744;
    border-radius: 80px;
    text-align: center;
    font-size: 20px;
    padding: 0 48px;
    font-family: Montserrat, Montserrat;
    font-weight: bold;
    display: inline-block;
  }
  .store-active {
    background: #f40744;
    color: #fff;
    margin: 40px;
  }
  .information {
    width: 1066px;
    margin: 0 auto;
    // overflow: hidden;
    // clear: both;
    height: 102px;
    div {
      float: left;
      width: 309px;
      padding-right: 83px;
      box-sizing: border-box;
    }
    div:last-child {
      width: 259px;
      padding: 0 0 0 133px;
    }
    .border {
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
      width: 448px;
      text-align: center;
      padding: 0;
    }
    p {
      font-size: 56px;
      font-family: Montserrat, Montserrat;
      font-weight: bold;
      color: #fff;
      line-height: 66px;
      margin-bottom: 8px;
    }
    h3 {
      font-size: 24px;
      font-family: Montserrat, Montserrat;
      font-weight: 600;
      color: #cecece;
      line-height: 28px;
      margin: 0;
    }
  }
  // 我们的业务范围
  .range {
    width: 1270px;
    margin: 154px auto 0;
    h3 {
      font-size: 40px;
      font-family: Montserrat, Montserrat;
      font-weight: 600;
      color: #fff;
      span {
        color: #25f4ee;
      }
      margin-bottom: 40px;
    }
  }
  .range-bg {
    background: url("../../assest/rangebg.png") top center no-repeat;
    background-size: cover;
    padding: 48px 76px 60px;
    border-radius: 40px;
    li {
      width: 314px;
      padding: 16px 0;
      border-bottom: 1px solid;
      border-image: linear-gradient(
          90deg,
          rgba(37, 244, 238, 0.8),
          rgba(37, 244, 238, 0.12)
        )
        1 1;
      border-image-slice: 0 0 1;
      text-align: left;
      line-height: 68px;
      span {
        margin: 0 24px;
        font-size: 30px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        color: #fff;
      }
      img {
        width: 68px;
        height: 68px;
        float: left;
      }
      clear: both;
      overflow: hidden;
    }
    li:nth-child(2n) {
      border-bottom: 1px solid;
      border-image: linear-gradient(
          90deg,
          rgba(37, 244, 238, 0.12),
          rgba(37, 244, 238, 0.8)
        )
        1 1;
      border-image-slice: 0 0 1;
      img {
        float: right;
      }
      span {
        float: right;
      }
    }
    .map {
      background: url("../../assest/map.png") top center no-repeat;
      background-size: contain;
      width: 638px;
      height: 439px;
      margin: 10px;
      position: relative;
      img {
        position: absolute;
        z-index: 5;
        width: 78px;
        height: 78px;
      }
      .drop1 {
        top: 68px;
        left: 50px;
      }
      .drop2 {
        top: 115px;
        left: 136px;
      }
      .drop3 {
        top: 67px;
        left: 275px;
      }
      .drop4 {
        top: 216px;
        left: 79px;
      }
      .drop5 {
        top: 322px;
        left: 149px;
      }
    }
  }
}
.pc-round-warp {
  width: 100%;
  position: relative;
  .round1 {
    width: 116px;
    height: 116px;
    background-color: #ff1652;
    border-radius: 50%;
    left: -58px;
    position: absolute;
    top: 60px;
  }
  .round2 {
    width: 134px;
    height: 134px;
    border: 22px solid #ff1652;
    border-radius: 50%;
    right: -78px;
    position: absolute;
    top: 60px;
  }
}
.pc-introduce {
  width: 1200px;
  margin: 160px auto 177px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .intr-imgs {
    position: relative;
    width: 540px;
    height: 567px;
    img {
      position: absolute;
    }
  }
  .tm1 {
    left: 33px;
    width: 159px;
    top: 0;
  }
  .tm2 {
    left: 215px;
    width: 185px;
    top: 47px;
  }
  .tm3 {
    left: 101px;
    width: 344px;
    top: 143px;
    z-index: 2;
  }
  .tm4 {
    right: 0;
    width: 123px;
    top: 17px;
    z-index: 3;
  }
  .tm5 {
    left: 0;
    width: 165px;
    bottom: 0;
    z-index: 3;
  }
  .tm6 {
    right: 32px;
    width: 124px;
    bottom: 35px;
    z-index: 3;
  }
  .desc img {
    display: block;
    margin-bottom: 16px;
  }
  .desc p {
    font-size: 20px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    color: #fff;
    line-height: 28px;
    margin-bottom: 20px;
    width: 549px;
  }
  .tik-warp {
    position: relative;
    width: 579px;
    height: 611px;

    img {
      position: absolute;
      z-index: 3;
      display: block;
      margin: 0;
    }
    .img1 {
      top: 19px;
      left: 209px;
      width: 242px;
      height: 492px;
      z-index: 4;
    }
    .img2 {
      bottom: -10px;
      left: 28px;
      width: 467px;
      height: 314px;
    }
    .img3 {
      top: 77px;
      left: 0;
      width: 262px;
      height: 168px;
      z-index: 5;
    }
    .img4 {
      top: 0;
      right: 29px;
      width: 146px;
      height: 129px;
      z-index: 5;
    }
    .img5 {
      top: 328px;
      right: 0;
      width: 175px;
      height: 203px;
      z-index: 5;
    }
  }
  .img-leader {
    display: block;
    width: 500px;
  }
  .desc1 {
    max-width: 540px;
    h3 {
      font-size: 64px;

      font-weight: 600;
      color: #fff;
      line-height: 75px;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      font-family: Montserrat, Montserrat;
      font-weight: 400;
      color: #fff;
      line-height: 28px;
      width: 540px;
    }
  }
}
.main-title {
  font-size: 52px;
  font-weight: 600;
  color: #ffffff;
  line-height: 61px;
  margin-bottom: 36px;
}
.cases {
  width: 1200px;
  margin: 160px auto;
  .btn {
    width: 161px;
    height: 56px;
    line-height: 56px;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #f2efef;
    font-size: 20px;
    display: inline-block;
    margin-right: 16px;
    cursor: pointer;
  }
  .active {
    background: #ff1652;
    color: #fff;
    border-color: #ff1652;
  }
  .cases-content {
    cursor: pointer;
    width: 100%;
    background-color: #ff1652;
    border-radius: 32px;
    overflow: hidden;
    clear: both;
    overflow: hidden;
    height: 460px;
    margin-top: 44px;
    img {
      width: 791px;
      height: 460px;
      float: left;
      display: block;
    }
    .text {
      width: 409px;
      float: right;
      h3 {
        font-size: 80px;
        color: #fff;
        line-height: 117px;
        margin-left: 40px;
        margin-top: 40px;
        font-weight: bold;
        span {
          font-size: 34px;
          font-weight: 500;
          color: #ffffff;
          line-height: 117px;
          vertical-align: bottom;
          margin-left: 14px;
        }
      }
      p {
        margin-left: 40px;
        font-size: 24px;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
}
.brand {
  width: 1200px;
  margin: 0 auto;
  .card {
    width: 372px;
    height: 500px;
    background: #131313;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 19px;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #ffffff;
      line-height: 28px;
      padding: 20px 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      padding: 0 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      height: 80px;
    }
    .img2 {
      width: 24px;
      height: 24px;
      display: block;
      margin-top: 11px;
      margin-right: 20px;
      float: right;
    }
    .img-warp {
      height: 300px;
      overflow: hidden;
    }
  }
  .card:hover {
    img {
      transform: scale(1.2);
    }
    .img2 {
      transform: scale(1);
    }
  }
}

.sub-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
}

// 服务类型
.pc-service-list {
  height: auto !important;
  border-radius: 28px;
  padding: 36px 105px 81px 40px;
  background: #fff;
  box-sizing: border-box;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .btn {
    display: inline-block;
    height: 35px;
    background: #fe2c55;
    border-radius: 23px;
    padding: 0 26px;
    line-height: 35px;
    font-size: 16px;
    color: #fff;
  }
  h3 {
    margin: 20px 0 12px;
    font-size: 32px;

    line-height: 38px;
  }
  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    width: 628px;
  }
  img {
    float: right;
  }
}
.pc-service {
  margin-bottom: 120px;
  .service1 {
    margin-top: -45px;
    background-color: #fe2c55;
    color: #fff;
    h3,
    p,
    h4 {
      color: #fff;
    }
    .btn {
      background-color: #fff;
      color: #fe2c55;
    }
  }
  .service2 {
    margin-top: -45px;
    background-color: #25f4ee;
    .btn {
      background-color: #000;
    }
    p {
      width: 747px;
    }
  }
  .service3 {
    margin-top: -45px;
    height: 296px;
    padding-bottom: 40px;
    p {
      width: 747px;
    }
  }
}
.public-content {
  width: 1200px;
  margin: 0 auto;
}

.pc-en-class {
  .home-banner {
    h2 {
      font-size: 62px;
      font-weight: bold;
    }
    h3 {
      font-size: 44px;
      font-weight: 600;
    }
    .information h3 {
      font-size: 24px;
      white-space: nowrap;
    }
    .information {
      div {
        width: 280px;
      }
      .border {
        width: 448px;
      }
      div:last-child {
        padding-left: 50px;
        width: 300px;
      }
    }
  }
  .pc-talent {
    .streamer-content {
      padding-top: 117px;
      h3 {
        line-height: 103px;
      }
      span {
        display: block;
      }
      p {
        font-size: 20px;
      }
    }
    .describe {
      font-weight: 400;
    }
  }
  .pc-business {
    .streamer-content {
      padding-top: 70px;
      span {
        display: block;
      }
    }
    .streamer1 .streamer-content {
      padding-top: 39px;
    }
    .application-list {
      h3 p {
        font-size: 48px;
      }
    }
    .streamer3 .streamer-content {
      padding-top: 93px;
    }
    .step-warp .step {
      p {
        line-height: 28px;
        font-size: 24px;
      }
    }
  }
  .pc-service-list h4 {
    margin-bottom: 10px;
  }
}
// 下载app
.pc-download {
  .small-label {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 200;
    background-color: #25f4ee;
    color: #fff;
    cursor: pointer;
    width: 61px;
    padding: 16px 14px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Montserrat, Montserrat;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
    text-align: center;
    margin-top: -51px;
    p {
      word-wrap: break-word;
      text-align: center;
    }
    img {
      display: block;
      width: 16px;
      margin: 6px auto 0;
    }
    // transition: all 2s;
    // -webkit-transition: all 2s;
  }
  .big-label {
    position: fixed;
    right: 16px;
    top: 50%;
    z-index: 200;
    cursor: pointer;
    margin-top: -122px;
    .bg {
      background-color: #202020;
      padding: 12px;
      box-sizing: border-box;
      border-radius: 12px;
      border: 3px solid #25f4ee;
      border-top: 0;
      border-bottom: 0;
      border-right-color: #fe2c55;
      p {
        font-size: 14px;
        font-family: Montserrat, Montserrat;
        font-weight: 400;
        color: #fff;
        line-height: 16px;
        margin-bottom: 12px;
        text-align: center;
        width: 156px;
      }
      img {
        width: 156px;
      }
    }
    .d-img {
      width: 24px;
      margin: 12px auto 0;
      display: block;
    }
    // transition: width 2s;
    // -webkit-transition: width 2s;
  }
}

.mapAnimated {
  -webkit-animation-name: mapFadeIn;
  animation-name: mapFadeIn;
}
// @-webkit-keyframes mapFadeIn {
//   0% {
//     -webkit-transform: scale(0);
//     transform: scale(0);
//   }
//   // 10% {
//   //   -webkit-transform: scale(0.2);
//   //   transform: scale(0.2);
//   // }
//   // 20% {
//   //   -webkit-transform: scale(0.4);
//   //   transform: scale(0.4);
//   // }
//   // 30% {
//   //   -webkit-transform: scale(0.55);
//   //   transform: scale(0.55);
//   // }
//   // 40% {
//   //   -webkit-transform: scale(0.75);
//   //   transform: scale(0.75);
//   // }
//   50% {
//     -webkit-transform: scale(1);
//     transform: scale(1);
//   }
//   60% {
//     -webkit-transform: translate(3px, 3px);
//     transform: translate(3px, 3px);
//   }
//   70% {
//     -webkit-transform: translate(-3px, -3px);
//     transform: translate(-3px, -3px);
//   }
//   80% {
//     -webkit-transform: translate(3px, 3px);
//     transform: translate(3px, 3px);
//   }
//   90% {
//     -webkit-transform: translate(-3px, -3px);
//     transform: translate(-3px, -3px);
//   }
//   100% {
//     -webkit-transform: translate(0, 0);
//     transform: translate(0, 0);
//   }
// }
@keyframes mapFadeIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: rotate(5deg) translate3d(5px);
    transform: rotate(5deg) translate(5px);
  }
  80% {
    -webkit-transform: rotate(-5deg) translate3d(-5px);
    transform: rotate(-5deg) translate(-5px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate3d(0px);
    transform: rotate(0deg) translate(0px);
  }
  // 8% {
  //   -webkit-transform: rotate(0deg);
  //   transform: rotate(0deg);
  // }
  // 90% {
  //   -webkit-transform: rotate(-5deg);
  //   transform: rotate(-5deg);
  // }
  // 100% {
  //   -webkit-transform: rotate(0deg);
  //   transform: rotate(0deg);
  // }
}
.mapAnimatedAll {
  -webkit-animation-name: mapMoveAll;
  animation-name: mapMoveAll;
}
@keyframes mapMoveAll {
  0% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0);
  }
  25% {
    -webkit-transform: translate(-3px, 0);
    transform: translate(-3px, 0);
  }
  50% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0);
  }
  90% {
    -webkit-transform: translate(-3px, 0);
    transform: translate(-3px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
