.app-news-list {
  width: 100%;
  background-color: #f2f2f2;
  .banner {
    width: 100%;
    background: url("../images/newbg.png") no-repeat;
    background-color: #000;
    background-size: cover;
    padding: 0.26rem 0 0.34rem;
    text-align: center;
    height: 2rem;
    box-sizing: border-box;
    h3 {
      font-size: 0.64rem;
      font-weight: bold;
      color: #fff;
      // margin-bottom: 0.12rem;
      text-align: center;
    }
    p {
      font-size: 0.28rem;
      font-weight: 400;
      color: #fff;
    }
  }
  .list-warp {
    padding: 0.32rem 0.4rem;
    .list {
      background-color: #fff;
      height: 2.24rem;
      border-radius: 0.24rem;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.32rem;
      .img {
        width: 2.24rem;
        height: 2.24rem;
        object-fit: cover;
        display: block;
      }
      .text {
        padding: 0.24rem 0.32rem 0.24rem 0.24rem;
        flex: 1;
        box-sizing: border-box;
        h3 {
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 0.96rem;
          font-size: 0.24rem;
          font-weight: 500;
          color: #000;
          line-height: 0.32rem;
          -webkit-line-height: 0.32rem;
        }
      }
      .more {
        margin-top: 0.5rem;
        text-align: right;
        line-height: 0.23rem;
        height: 0.23rem;
        font-size: 0.2rem;

        font-weight: 500;
        color: #000;
        img {
          width: 0.23rem;
          margin-left: 0.1rem;
          vertical-align: bottom;
        }
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 0.62rem;
  }
  .ant-pagination-item {
    border: none;
    font-size: 0.24rem;

    font-weight: 500;
    height: 0.4rem;
    line-height: 0.43rem;
    margin-right: 0;
    a {
      color: #646464;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 0.4rem;
    height: 0.4rem;
    margin-right: 0;
  }
  .ant-pagination-item-active a,
  .ant-pagination-item a:hover,
  .ant-pagination-item-active {
    color: #fe2c55;
    background: none;
  }
}
