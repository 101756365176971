// 立即加入
.app-join-now {
  // padding: 0 0.4rem;
  .app-main-title {
    text-align: center;
    margin: 0.96rem 0.4rem 0.2rem;
  }
  p {
    font-size: 0.32rem;
    font-weight: 400;
    color: #fff;
    line-height: 0.38rem;
    margin-bottom: 0.48rem;
    text-align: center;
    padding: 0 0.4rem;
  }
  .btn {
    height: 0.78rem;
    border-radius: 3rem;
    width: 1.8rem;
    line-height: 0.78rem;
    display: block;
    margin: 0.48rem auto 0;
    font-size: 0.24rem;
    font-weight: 600;
    background: #f40744;
    color: #fff;
    text-align: center;
    border-color: #f40744;
    box-sizing: border-box;
    padding: 0;
  }
  .btn:hover,
  .btn:active,
  .btn:focus,
  .btn:hover {
    background-color: #f40744 !important;
    border-color: #f40744 !important;
  }

  .btn:disabled {
    background: #424242 !important;
    border-color: #424242 !important;
    color: #808080 !important;
  }
  .btn:disabled:hover {
    background: #424242 !important;
    border-color: #424242 !important;
    color: #808080 !important;
  }
  .ant-input-number-group-wrapper {
    display: block;
  }
  .ant-select-arrow {
    color: #fff;
    background: url("../../assest/down2.png") no-repeat;
    background-size: cover;
    width: 0.48rem;
    height: 0.48rem;
    margin-top: -0.24rem;
    right: 0.1rem;
    // span {
    //   display: none;
    // }
    svg {
      visibility: hidden;
    }
  }

  // 单纯输入框
  .ant-input {
    background: none;
    border: 0.02rem solid #fff;
    border-radius: 0.2rem;
    color: #fff;
    height: 0.92rem;
    font-size: 0.24rem;
  }
  .ant-input:hover,
  .ant-input:focus {
    border-color: #fff;
  }
  // 选择框
  .ant-select {
    color: #fff;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
    border: 0.02rem solid #fff;
    border-radius: 0.2rem;
    height: 0.92rem !important;
    font-size: 0.24rem;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
      .ant-select-customize-input
    )
    .ant-select-selection-search-input {
    height: 0.88rem;
    line-height: 0.88rem;
  }
  .ant-input-number-input {
    // padding: 0.3rem 0.28rem;
    // height: 0.8rem;
    // line-height: 0.4rem;
    margin-top: 0.1rem;
    line-height: 0.5rem;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    height: 0.88rem;
    line-height: 0.88rem;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #fff;
    box-shadow: none;
    border-right-width: 0.02rem;
  }
  // 左侧有内容输入框
  .ant-input-number-handler-wrap {
    // 数字加减
    display: none;
  }
  .ant-input-number-group,
  .ant-input-group {
    color: #fff;
    height: 0.92rem;
    border: 0.02rem solid #fff;
    border-radius: 0.2rem;
    overflow: hidden;
  }
  .ant-input-number,
  .ant-input-number-group-addon,
  .ant-input-group-addon {
    background: none;
    border: none;
    color: #fff;
  }
  .ant-input-number-group-addon
    .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-number-group-addon .ant-select-selector:hover {
    border: none;
  }
  .ant-input-number-group-addon {
    .ant-select-selection-item {
      color: #fff;
    }
    .ant-select-selection-item {
      text-overflow: inherit;
    }
  }
  .ant-input-number-group,
  .ant-input-group {
    .ant-input,
    .ant-input:focus,
    .ant-input-number,
    .ant-input-number:focus,
    .ant-input-number-focused,
    .ant-input-focused {
      border: none !important;
      box-shadow: none;
    }
  }
  .ant-input-number-group-addon::after,
  .ant-input-group-addon::after {
    position: absolute;
    top: 0.24rem;
    right: 0;
    z-index: 3;
    width: 0.03rem;
    height: 0.44rem;
    background-color: #fff;
    content: "";
  }
  .ant-select-selection-placeholder,
  input::placeholder,
  .ant-input-number-input::placeholder {
    color: #707070;
    font-weight: 400;
    font-size: 0.24rem;
    // line-height: 0.8rem;
    font-family: Montserrat, Montserrat;
  }
  .platform {
    .ant-select-selector,
    input,
    .ant-input {
      border: none !important;
    }
    .ant-input-group-addon {
      background: none;
    }
    .demo-option-label-item {
      img {
        width: 0.48rem;
        height: auto;
        vertical-align: middle;
        border-radius: 0.08rem;
      }
      span {
        display: none;
      }
    }
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    min-height: 0.92rem !important;
    height: auto !important;
  }
  .ant-select-multiple .ant-select-selection-item {
    background: #333;
    border-color: #333;
    border-radius: 0.1rem;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: #fff !important;
  }

  .hide-keyboard
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    min-height: 0.92rem !important;
    height: auto !important;
  }
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: none !important;
}
.app-platform_account {
  width: 6.7rem !important;
}
.app-platform-select {
  // width: 6.7rem !important;
  .ant-select-item-option-selected {
    background: none !important;
    color: #ff1652 !important;
  }
  .anticon-check,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: #ff1652;
  }
  .demo-option-label-item {
    padding: 0.1rem 0;
    font-family: Montserrat, Montserrat;
    color: #000;
    font-size: 0.24rem;
  }
  .demo-option-label-item img {
    width: 0.48rem;
    vertical-align: middle;
    margin-right: 0.1rem;
  }
  .rc-virtual-list-holder {
    max-height: 300px !important;
  }
}
