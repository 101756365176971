.app-new-content {
  width: 100%;
  // .crumbs {
  //   // width: 329px;
  //   padding: 0 20px;
  //   height: 41px;
  //   line-height: 41px;
  //   display: inline-block;
  //   a {
  //     font-size: 16px;
  //     color: #fff;
  //     float: left;
  //     font-weight: 400;
  //   }

  //   .active {
  //     width: 241px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }
  //   img {
  //     width: 16px;
  //     height: 16px;
  //     margin: 12px 4px 0;
  //     float: left;
  //   }
  // }

  .title {
    font-size: 0.4rem;
    font-weight: bold;
    color: #fff;
    line-height: 0.47rem;
    margin: 0.52rem 0 0.48rem;
    padding: 0 0.4rem;
  }
  .news-detail {
    background: #fff;
    padding: 0.32rem 0.4rem;
    width: 100%;
    box-sizing: border-box;

    img {
      display: block;
      width: 6.7rem;
      margin: 0 auto 0.32rem;
      border-radius: 0.4rem;
    }
    .img1 {
      width: 6.7rem;
      margin-bottom: 0.48rem;
    }
    .img2 {
      margin-top: 0.8rem;
      width: 6.7rem;
      margin-bottom: 0.48rem;
    }
    .img3 {
      margin-top: 0.8rem;
      width: 3.62rem;
      margin-bottom: 0.48rem;
      border-radius: 0;
    }
    p {
      font-size: 0.28rem;
      font-weight: 400;
      color: #000;
      line-height: 0.36rem;
      margin-bottom: 0.32rem;
    }
    .fix-text {
      width: 6.7rem;
      background: #ebebeb;
      margin: 0 auto;
      border-radius: 0.4rem;
      padding: 0.32rem;
      box-sizing: border-box;
    }
    h2,
    h1 {
      font-size: 0.34rem;
      font-weight: 600;
      color: #000;
      line-height: 0.42rem;
      margin-bottom: 0.24rem;
    }
    h3,
    h4 {
      font-size: 0.32rem;
      font-weight: 600;
      color: #000;
      line-height: 0.42rem;
      margin-bottom: 0.24rem;
    }
  }
}
